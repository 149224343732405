<template>
    <div class="py-3 px-6">
        <h3 class="text-[16px] font-semibold mb-4">Early Deposit</h3>
        <DataTable :value="rowData.clients" :pt="subtableTheadStyles" :data-key="(data) => data.id">
            <Column field="role" header="Sender" :pt="headerCellStyleOverrides" body-class="pr-4 border-x-transparent">
                <template #body="{ data: clientData }: { data: EarlyDeposit['clients'][number] }">
                    <div class="font-semibold">{{ 'Buyer' }}</div>
                    <div>{{ clientData.email }}</div>
                </template>
            </Column>
            <Column header="Amount" :pt="headerCellStyleOverrides" body-class="border-x-transparent">
                <template #body>
                    {{ formatMoney(rowData.deposit_amount / 100) }}
                </template>
            </Column>
            <Column field="created_at" header="Requested Date" :pt="headerCellStyleOverrides" body-class="border-x-transparent">
                <template #body>
                    {{ formatDate(rowData.created_at) }}
                </template>
            </Column>
            <Column field="payment_sent_date" header="Sent Date" :pt="headerCellStyleOverrides" body-class="border-x-transparent">
                <template #body="{ data: clientData }: { data: EarlyDeposit['clients'][number] }">
                    {{ clientData.pre_file_payments[0]?.payment_sent_date ?? '--' }}
                </template>
            </Column>
            <Column field="payment_status" header="Status" :pt="headerCellStyleOverrides" body-class="border-x-transparent">
                <template #body="{ data: clientData }: { data: EarlyDeposit['clients'][number] }">
                    {{ clientData.pre_file_payments[0]?.status ?? 'Requested' }}
                </template>
            </Column>
            <Column header="Actions" header-class="text-transparent" :pt="headerCellStyleOverrides" body-class="border-x-transparent">
                <!-- TODO: Show cancel X or show PDF of receipt -->
                <template #body="{ data: clientData }: { data: EarlyDeposit['clients'][number] }">
                    TBD
                </template>
            </Column>
        </DataTable>

        <h3 class="text-[16px] font-semibold mt-6 mb-4">Notification Recipients</h3>
        <DataTable :value="rowData.notifying_users" :pt="subtableTheadStyles">
            <Column field="email" header="Email" :pt="headerCellStyleOverrides" body-class="border-x-transparent" />
            <Column field="name" header="Name" :pt="headerCellStyleOverrides" body-class="border-x-transparent" />
        </DataTable>
    </div>
</template>

<script setup lang="ts">
import type { EarlyDeposit } from '@/api/interfaces/early-deposit-types';
import { formatDate } from '@/shared/utils/format-date';
import { formatMoney } from '@/shared/utils/format-number';
import Column from 'primevue/column';
import DataTable, { type DataTableProps } from 'primevue/datatable';

defineProps<{ rowData: EarlyDeposit }>();

const subtableTheadStyles: DataTableProps['pt'] = { thead: 'bg-[#F5F5F5] border-t border-t-neutral-600' } as const;
const headerCellStyleOverrides: DataTableProps['pt'] = { headerCell: 'bg-transparent border-b-transparent' } as const;
</script>
